app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        // JOB_MESSAGE_ACTION: '<strong>{{ ::message.sender }}</strong> added a message.',
        JOB_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> provided an update.',
        JOB_ACTIONED_ACTION: 'Action required deadline was advanced.',
        JOB_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created the job.',
        JOB_FILE_DELETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> deleted a job file.',
        JOB_FILE_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> added {{ ::event.metadata.files.length > 1 ? "" : "a" }} job file{{ ::event.metadata.files.length > 1 ? "s" : "" }}.',
        JOB_ALARM_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> updated the alarm.',
        JOB_APPROVAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent an approval request.',
        JOB_APPROVAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> approved the job.',
        JOB_APPROVAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected the job approval.',
        JOB_APPROVAL_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> resent an approval request to {{ ::event._links["approver"].title }}.',
        JOB_ASSIGNMENT_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent an assignment request to {{ ::event._links["operative"].title }}.',
        JOB_ASSIGNMENT_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reassigned the job.',
        JOB_ASSIGNMENT_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> accepted the job.',
        JOB_ASSIGNMENT_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected the job.',
        JOB_ASSIGNMENT_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> resent an assignment request to {{ ::event._links["operative"].title }}.',
        JOB_ASSIGNMENT_INSISTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> insists the job is accepted.',
        JOB_PROPOSAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent a proposal.',
        JOB_PROPOSAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> accepted the proposal.',
        JOB_PROPOSAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected the proposal.',
        JOB_EXTENSION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent an extension request.',
        JOB_EXTENSION_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> accepted the extension request.',
        JOB_EXTENSION_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected the extension request.',
        JOB_OPERATION_ACKNOWLEDGED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> acknowledged the job.',
        JOB_OPERATION_COMMENCED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> commenced the job.',
        JOB_OPERATION_COMPLETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> completed the job.',
        JOB_POSTPONE_REMINDER_AT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> changed the Action Required By date to {{ ::event.reminderAt | date:\'short\' }}.',
        JOB_OPERATION_MISSED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> missed the job.',
        JOB_OPERATION_CHASED_ACTION: 'A chase #{{ ::event.chaseSequence + 1 }} notification has been sent to <strong>{{ ::event._links["user"].title }}</strong>.',
        JOB_OPERATION_REOPENED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reopened the job.',
        JOB_OPERATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> cancelled the job.',
        JOB_APPROVAL_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escalated the job approval.',
        JOB_AUDIT_TRAIL_SENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent the audit trail.',
        JOB_EDITED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> edited the job.',
        JOB_OPERATIVE_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reassigned operative.',
        JOB_SLAUPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> updated the job.',
        JOB_EXPLICIT_ACCESS_GRANTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> granted job access to {{ ::event._links["privileged-user"].title }}.',
        JOB_EXPLICIT_ACCESS_REVOKED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> revoked job access for {{ ::event._links["privileged-user"].title }}.',
        JOB_EXPLICIT_ACCESS_EXPIRED_ACTION: 'Job access expired for {{ ::event._links["privileged-user"].title }}.',
        JOB_REMEDIAL_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created remedial job.',
        QUOTE_REMEDIAL_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created remedial quote.',
        ACTION_REMEDIAL_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created remedial action.',
        JOB_ADDITIONAL_OPERATIVE_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> added additional operative.',
        JOB_ADDITIONAL_OPERATIVE_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> removed additional operative.',

        // Buddied
        JOB_ASSIGNMENT_ACCEPTED_BUDDIED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> (Buddying For: <strong>{{ ::event._links["buddiedForUser"].title }}</strong>) accepted the job.',
        JOB_ASSIGNMENT_REJECTED_BUDDIED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> (Buddying For: <strong>{{ ::event._links["buddiedForUser"].title }}</strong>) rejected the job.',
        JOB_ASSIGNMENT_REASSIGNED_BUDDIED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> (Buddying For: <strong>{{ ::event._links["buddiedForUser"].title }}</strong>) reassigned the job.',
        JOB_ASSIGNMENT_INSISTED_BUDDIED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> (Buddying For: <strong>{{ ::event._links["buddiedForUser"].title }}</strong>) insists the job is accepted.',
        JOB_PROPOSAL_ACCEPTED_BUDDIED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> (Buddying For: <strong>{{ ::event._links["buddiedForUser"].title }}</strong>) accepted the proposal.',
        JOB_PROPOSAL_REJECTED_BUDDIED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> (Buddying For: <strong>{{ ::event._links["buddiedForUser"].title }}</strong>) rejected the proposal.',
        JOB_APPROVAL_ACCEPTED_BUDDIED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> (Buddying For: <strong>{{ ::event._links["buddiedForUser"].title }}</strong>) approved the job.',
        JOB_APPROVAL_REJECTED_BUDDIED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> (Buddying For: <strong>{{ ::event._links["buddiedForUser"].title }}</strong>) rejected the job approval.',
        JOB_OPERATION_REOPENED_BUDDIED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> (Buddying For: <strong>{{ ::event._links["buddiedForUser"].title }}</strong>) reopened the job.',
        JOB_OPERATION_CANCELLED_BUDDIED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> (Buddying For: <strong>{{ ::event._links["buddiedForUser"].title }}</strong>) cancelled the job.',
        JOB_EXTENSION_ACCEPTED_BUDDIED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> (Buddying For: <strong>{{ ::event._links["buddiedForUser"].title }}</strong>) accepted the extension request.',
        JOB_EXTENSION_REJECTED_BUDDIED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> (Buddying For: <strong>{{ ::event._links["buddiedForUser"].title }}</strong>) rejected the extension request.',

    });
});
